// TODO: CLEAN this file as it copied from another one which support different requirements
import 'ol/ol.css'

import * as actions from "../../store/actions"
import { injectIntl } from "gatsby-plugin-intl"
import MapService, { createEmptyMap } from '../../utils/mapService'
import { markerStyle } from '../../utils/index'
// import FeatureIdentify from '../../utils/identifyService'
// import Layout from "../../containers/Layout"
import React from "react"
import { connect } from "react-redux"
import proj4 from 'proj4'
import { METERS_PER_UNIT } from 'ol/proj/Units'
import { register } from 'ol/proj/proj4'
import withStyles from 'react-jss'
import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";

import '../../assets/css/maps_page.css'
import '../../assets/css/loading-spinner.css'
import GCCLogo from '../../assets/img/gcc-logo-small.png'

import Circle from 'ol/geom/Circle';
import Overlay from 'ol/Overlay.js';
// import { toStringHDMS } from 'ol/coordinate.js';
// import { fromLonLat, toLonLat } from 'ol/proj.js';

const styles = {
  mapDiv: {
    height: '600px',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  mapContainer: {
    // padding: 20,
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  staticMapContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  navColor: {
    backgroundColor: '#56c9c7'
  },
  olPopup: {
    position: 'absolute',
    backgroundColor: 'white',
    '-webkit-filter': 'drop-shadow(0 1px 4px rgba(0,0,0,0.2))',
    filter: 'drop-shadow(0 1px 4px rgba(0,0,0,0.2))',
    padding: '15px',
    borderRadius: '10px',
    border: '1px solid #cccccc',
    bottom: '12px',
    left: '-50px',
    minWidth: '280px',
    before: {
      top: '100%',
      border: 'solid transparent',
      content: " ",
      height: 0,
      width: 0,
      position: 'absolute',
      pointerEvents: 'none',
      borderTopColor: '#cccccc',
      borderWidth: '11px',
      left: '48px',
      marginLeft: '-11px',
    },
    after: {
      top: '100%',
      border: 'solid transparent',
      content: " ",
      height: 0,
      width: 0,
      position: 'absolute',
      pointerEvents: 'none',
      borderTopColor: 'white',
      borderWidth: '10px',
      left: '48px',
      marginLeft: '-10px',
    }
  },
  olPopupCloser: {
    textDecoration: 'none',
    position: 'absolute',
    top: '5px',
    right: '8px',
    border: '0.3px solid',
    paddingLeft: '6px',
    paddingRight: '6px',
    paddingTop: '3px',
    borderRadius: '25%',
  },
  popupContent: {
    marginTop: '15px',
  }
}
register(proj4)

class AchievementsMapPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      map: null,
      featureIdentifyLoading: false,
      activeFeature: 0,
      featureIdentifyResult: [],
    }
    this.updateMapSize = this.updateMapSize.bind(this)
    this.tilesLoadingArray = []
  }

  componentDidMount() {
    this.setState({ featureIdentifyLoading: true })
    const map = createEmptyMap();
    this.mapService = new MapService(map)
    this.setState({ map, selectedRegion: this.props.selectedRegion }, () => {
      const { selectedRegion } = this.state
      this.createOverlay()
      selectedRegion && this.mapService.zoomToLocation(selectedRegion.location)
      this.addMarkerIdentifyListener()
    })
    this.props.onRef(this)
  }
  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  addMarkerIdentifyListener() {
    const { map } = this.state
    map.on('singleclick', (e) => {
      const features = map.getFeaturesAtPixel(e.pixel)
      features && this.markerIdentify(features, e)
    })
  }
  createOverlay = () => {
    const { map } = this.state
    document.documentElement.classList.remove("nav-open")
    this.fetchMapExplorerConfig(this.props.intl.locale)
    map.setTarget(document.getElementById('map'))

    /**
     * Elements that make up the popup.
     */
    let container = document.getElementById('popup');
    this.content = document.getElementById('popup-content');
    var closer = document.getElementById('popup-closer');


    /**
     * Create an overlay to anchor the popup to the map.
     */
    this.overlay = new Overlay({
      element: container,
      autoPan: true,
      autoPanAnimation: {
        duration: 250
      }
    });
    map.addOverlay(this.overlay);

    closer.onclick = function () {
      this.overlay.setPosition(undefined);
      closer.blur();
      return false;
    }.bind(this)
    // map.on('singleclick', (evt) => {
    //   this.setState({
    //     featureIdentifyLoading: true,
    //     activeFeature: 0,
    //     featureIdentifyResult: [],
    //   }, () => this.identify(evt))
    // })
  }

  componentWillReceiveProps(nextProps) {
    const { selectedRegion } = nextProps
    const currentRegionId = this.props.selectedRegion && this.props.selectedRegion.id
    const nextRegionId = selectedRegion && selectedRegion.id
    if (currentRegionId !== nextRegionId || this.props.selectedCategoryIds !== nextProps.selectedCategoryIds || this.props.selectedOwnerType !== nextProps.selectedOwnerType) {
      this.mapService.updateSize()
      this.mapService.clearMapLayers()
      this.createAchievementsLayer(this.props.intl.locale, nextProps.selectedRegion,
        nextProps.selectedCategoryIds, nextProps.selectedOwnerType)
      selectedRegion && this.mapService.addZoomToExtentControl(selectedRegion.location)
      selectedRegion && this.mapService.zoomToLocation(selectedRegion.location)
    }
  }

  mapLoadingStart = () => {
    this.tilesLoadingArray.push(0)
    if (this.tilesLoadingArray.length > 0 && !(this.state.featureIdentifyLoading)) {
      this.setState({ featureIdentifyLoading: true })
    }
  }


  mapLoadingEnd = () => {
    // When loading first time
    if (this.tilesLoadingArray.length === 3)
      this.tilesLoadingArray.splice(0, 2)

    this.tilesLoadingArray.pop()
    if ((this.tilesLoadingArray.length < 1) && (this.state.featureIdentifyLoading)) {
      this.setState({ featureIdentifyLoading: false })
    }
  }

  createAchievementsLayer = (locale, region, categories, ownerType) => {
    this.mapLoadingStart()
    const VectorLayer = require('ol/layer/Vector').default;
    let layer_url = process.env.SERVER_URL + locale + '/rest/geojson/achievements/'
    var regionParam = region && region.code !== "GCC" ? `?region=${region.id}` : "?region="
    var categoryParam = categories.length !== 0 ? `&categories=${categories.join()}` : ""
    var ownerParam = ownerType && ownerType !== "all" ? `&owner_type=${ownerType}` : ""

    layer_url += regionParam + categoryParam + ownerParam;

    this.achievements_source = new VectorSource({
      format: new GeoJSON(),
      url: layer_url
    })

    let layer = new VectorLayer({
      visible: true,
      title: 'achievements',
      source: this.achievements_source,
      style: markerStyle([81, 188, 218])
    })

    this.mapService.map.addLayer(layer)

    if (region)
      this.mapService.zoomToLocation(region.location)

    this.achievements_source.on('change', () => this.mapLoadingEnd())
  }

  fetchMapExplorerConfig = locale => {
    const { selectedRegion, selectedCategoryIds } = this.props
    this.createAchievementsLayer(locale, selectedRegion, selectedCategoryIds)
  }

  getRegionById = (config, id) => {
    let target = null
    for (let index = 0; index < config.length; index++) {
      const region = config[index];
      if (region.id === id) {
        target = region
        break;
      }
    }
    return target
  }


  // TODO: review identify tolerance
  getIdentifyRadius = () => {
    //var map = this.getMap();
    const { map } = this.state
    let view = map.getView();
    let resolution = view.getResolution();
    let units = map.getView().getProjection().getUnits();
    let dpi = 25.4 / 0.28;
    let mpu = METERS_PER_UNIT[units];
    let scale = resolution * mpu * 39.37 * dpi;
    let divScale = 500;// to adjusting
    return scale / divScale;

  };

  // TODO: support popup paging
  // TODO: revise identify approach
  // add buffer(radius) to evt coordinate and get features intersect with it.
  identify = (evt) => {
    let identify_area = new Circle(evt.coordinate, this.getIdentifyRadius(), 'XY')
    let features = this.achievements_source.getFeaturesInExtent(identify_area.getExtent())
    if (features.length > 0) {
      let currentFeature = features[0]
      this.content.innerHTML = '<a href=/' + this.props.intl.locale + '/achievementsDetails/?id=' +
        currentFeature.id_ + ' rel="noopener noreferrer">' + currentFeature.get('title') + '</a>';
      this.overlay.setPosition(evt.coordinate);
    }
    this.setState({
      featureIdentifyLoading: false,
      activeFeature: 0,
      featureIdentifyResult: features,
    })
  }

  markerIdentify = (features, evt) => {
    if (features.length > 0) {
      let currentFeature = features[0]
      this.content.innerHTML = '<a href=/' + this.props.intl.locale + '/achievementsDetails/?id=' +
        currentFeature.id_ + '  rel="noopener noreferrer">' + currentFeature.get('title') + '</a>';
      this.overlay.setPosition(evt.coordinate);
    }
    this.setState({
      featureIdentifyLoading: false,
      activeFeature: 0,
      featureIdentifyResult: features,
    })
  }

  updateMapSize() {
    const { selectedRegion } = this.props
    this.mapService && this.mapService.updateSize(selectedRegion && selectedRegion.location)
  }

  render() {
    const { classes } = this.props
    const LoadingSpinner = () => (
      <div id='gcc-loading-mask'>
        <img src={GCCLogo} alt="" />
      </div>
    )
    return (
      <div className="main">
        <div className="container text-center">
          <div className={'map-container'}>
            <div className={'map-wrapper'}>
              {
                this.state.featureIdentifyLoading &&
                <LoadingSpinner />
              }
              <div id="map" className={classes.mapDiv}></div>
            </div>
          </div>
          <div id="popup" className={classes.olPopup}>
            <button id="popup-closer" className={classes.olPopupCloser}>X</button>
            <div id="popup-content" className={classes.popupContent}></div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    mapExplorerConfig: state.mapExplorerConfig,
    selectedRegion: state.regions.selectedRegion,
    regions: state.regions.regions
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setMapExplorerConfig: config => dispatch(actions.setMapExplorerConfig(config)),
    setSelectedRegion: regionCode => dispatch(actions.setSelectedRegion(regionCode)),
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(injectIntl(AchievementsMapPage)))
