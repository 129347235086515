import React from "react"
import { connect } from "react-redux"
import ReactPaginate from 'react-paginate'
import { Container, Row, Col, Card, CardBody, CardText, Spinner, TabContent, TabPane, Nav, NavItem, NavLink, } from "reactstrap"
import { injectIntl, FormattedMessage, navigate, Link } from "gatsby-plugin-intl"

import AchievmentsCategories from "../components/achievements/categories"
import SEO from "../components/seo"
import Layout from "../containers/Layout"
import NoImg from "../images/no_img.png"
import axios from "../api/axios"
import * as actions from "../store/actions"
import MapComponent from '../components/achievements/AchievementsMap'
import withStyles from 'react-jss'
import classnames from 'classnames';
import '../assets/css/custom-ol-layerswitcher.css'

const ACHIEVEMENTS_LIMIT = 9;
const achievementsOwnerTypes = [
  { value: "all", name: "all" },
  { value: "GOVERNMENT", name: "government" },
  { value: "NONGOVERNMENT", name: "non_government" },
  { value: "VOLUNTEERS", name: "volunteers" }
]
const styles = {
  mapDiv: {
    height: '600px',
    width: '800px',
    paddingTop: '50px',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  mapContainer: {
    padding: 20,
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  staticMapContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  navColor: {
    backgroundColor: '#56c9c7'
  },
  propDiv: {
    display: 'flex',
    justifyJontent: 'space-evenly'
  },
  propsTable: {
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  tableButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  navItem: {
    cursor: 'pointer',
  }
}

class AchievmentsPage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      allAchievements: [],
      selectedAll: "all",
      selectedCategories: [],
      isLoading: true,
      totalAchievementsCount: 0,
      achievementsOffset: 0,
      activeTab: '1',
      ownerType: { value: "all" }
    }
  }

  componentDidMount() {
    this.props.setActiveNavbarItem("achievements");

    if (this.props.selectedRegionId != null) {
      var categoryUrlParam = new URL(window.location.href).searchParams.get("category") || "all";
      var ownerUrlParam = new URL(window.location.href).searchParams.get("owner") || "all";
      this.setState({
        selectedAll: categoryUrlParam === "all" ? categoryUrlParam : "",
        selectedCategories: categoryUrlParam !== "all" ? this.state.selectedCategories.concat(parseInt(categoryUrlParam)) : [],
        ownerType: { value: ownerUrlParam }
      },
        () => { this.fetchAchievements() })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedRegionId !== prevProps.selectedRegionId) {
      var categoryUrlParam = new URL(window.location.href).searchParams.get("category") || "all";
      var ownerUrlParam = new URL(window.location.href).searchParams.get("owner") || "all";
      if (!achievementsOwnerTypes.some(el => el.value === ownerUrlParam))
        ownerUrlParam = "all"
      this.setState({
        selectedAll: categoryUrlParam === "all" ? categoryUrlParam : "",
        selectedCategories: categoryUrlParam !== "all" ? this.state.selectedCategories.concat(parseInt(categoryUrlParam)) : [],
        ownerType: { value: ownerUrlParam }
      },
        () => { this.fetchAchievements() })
    }
  }
 
  fetchAchievements = () => {
    this.setState({ isLoading: true });
    var regionParam = this.props.selectedRegionCode !== "GCC" ? `&region=${this.props.selectedRegionId}` : ""
    var categoryParam = this.state.selectedAll !== "all" ? `&categories=${this.state.selectedCategories.join()}` : ""
    var ownerParam = this.state.ownerType.value !== "all" ? `&owner_type=${this.state.ownerType.value}` : ""

    axios(this.props.intl.locale, `achievements/?offset=${this.state.achievementsOffset}`
      + regionParam + categoryParam + ownerParam)
      .then(response => {
        const achievements = [...response.data.results]
        this.setState({
          totalAchievementsCount: response.data.count,
          allAchievements: achievements,
          isLoading: false
        });
      }).catch(error => {
        const achievements = []
        this.setState({
          totalAchievementsCount: 0,
          allAchievements: achievements,
          isLoading: false
        });
      })


    navigate("/achievements/?country=" + this.props.selectedRegionCode
      + "&category=" + (this.state.selectedCategories.length === 0 ? "all" : this.state.selectedCategories.join()) + "&owner=" + this.state.ownerType.value)
  }

  handleAchievementCategoryClick = (achievementCategory) => {
    if (this.state.selectedCategories.includes(achievementCategory.id)) {
      let updatedSelectedCategories = this.state.selectedCategories.filter(selectedCategory => {
        return selectedCategory !== achievementCategory.id
      });

      if (updatedSelectedCategories.length === 0)
        this.handleAchievementCategoryClick({ id: "all" })

      this.setState({ selectedCategories: updatedSelectedCategories }, () => { this.fetchAchievements() });

    } else {
      const selectedCategories = achievementCategory.id !== "all" ? this.state.selectedCategories.concat(achievementCategory.id) : []
      const selectedAll = achievementCategory.id === "all" ? achievementCategory.id : ""
      this.setState({ selectedAll: selectedAll, achievementsOffset: 0, selectedCategories }, () => {
        this.fetchAchievements()
      })
    }
  }

  filterByOwnerType = (type) => {
    this.setState({ achievementsOffset: 0, ownerType: type }, () => {
      this.fetchAchievements()
    })
  }

  handlePageClick = data => {
    let offset = Math.ceil(data.selected * ACHIEVEMENTS_LIMIT);
    this.setState({ achievementsOffset: offset }, () => {
      this.fetchAchievements();
    });
    if (typeof window !== `undefined`) {
      window.scrollTo(0, 0);
    }
  }

  toggle = (tab) => {
    this.setState({
      activeTab: tab
    }, () => {
      tab === '2' && this.mapRef.updateMapSize()
    })
  }

  render() {
    const { classes } = this.props
    const noAchievementsMessage = <h3><FormattedMessage id="no_achievements_to_display" /></h3>
    const isArabicLocale = this.props.intl.locale === "ar";
    var pagesCount = this.state.totalAchievementsCount / ACHIEVEMENTS_LIMIT;
    var pagination = pagesCount > 1 ?
      <ReactPaginate
        previousLabel={<FormattedMessage id="previous" />}
        breakLabel={'...'}
        nextLabel={<FormattedMessage id="next" />}
        pageCount={pagesCount}
        marginPagesDisplayed={0}
        pageRangeDisplayed={2}
        onPageChange={this.handlePageClick}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'} />
      : null;
    const { activeTab } = this.state

    const activeFilterClass = "btn btn-outline-default"
    const nonActiveFilterClass = "btn disabled btn-outline-default"

    const AchievementsGrid =
      <Container>
        <Row className=" justify-content-center">
          {this.state.isLoading ? <Spinner />
            : this.state.allAchievements.length === 0 ? noAchievementsMessage
              : this.state.allAchievements.map((achievement, i) =>
                <Col lg={4} md={6} sm={6} key={i}>
                  <Link to={"/achievementsDetails/?country=" + this.props.selectedRegionCode + "&id=" + achievement.id}>
                    <Card className="achievements-card">
                      <img className="photo" src={achievement.image || NoImg} />
                      <CardBody>
                        <h5>{isArabicLocale ? achievement.title_ar : achievement.title_en}</h5>
                        <p>{achievement.construction_date}</p>
                        <hr align="center" className="hr-achievement-card" />
                        <CardText style={{ fontSize: "13px" }} >{isArabicLocale ? achievement.short_description_ar : achievement.short_description_en}</CardText>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>)}
        </Row>
        <Row className=" justify-content-center">
          {pagination}
        </Row>
      </Container>

    const OwnerFilter = <div className="links-wrapper">
      {achievementsOwnerTypes.map((type) => {
        return (
          <div className="links-item-wrapper" key={"links_page" + type.name}>
            <button
              onClick={() => this.filterByOwnerType(type)}
              className={this.state.ownerType.value === type.value ? activeFilterClass : nonActiveFilterClass}>
              <FormattedMessage id={type.name} />
            </button>
            &nbsp;&nbsp;&nbsp;
          </div>
        )
      })}
    </div>


    const NavItems = () => <Nav tabs className={"justify-content-center maps-nav margin-bottom-40"}>
      <NavItem className={classnames({ [classes.navItem]: true, custom_nav: activeTab === '1' })}>
        <NavLink
          className={classnames({ active: activeTab === '1', [classes.navColor]: true })}
          onClick={() => { this.toggle('1'); }}>
          <h5><FormattedMessage id="achievements" /></h5>
        </NavLink>
      </NavItem>
      <NavItem className={classnames({ [classes.navItem]: true, custom_nav: activeTab === '2' })}>
        <NavLink
          className={classnames({ active: activeTab === '2', [classes.navColor]: true })}
          onClick={() => { this.toggle('2'); }}
        >
          <h5><FormattedMessage id="achievements_map" /></h5>
        </NavLink>
      </NavItem>
    </Nav>

    return (
      <Layout>
        <SEO title="Achievements" />
        <div className="main">
          <div className="section text-center">
            <div className="mt-5 p-4 flex" style={{ backgroundColor: "#d3d3d36e" }} >
              <Container>
                <Row >
                  <h4 className="mt-1"><FormattedMessage id="achievements_app" /></h4>
                </Row>
              </Container>
            </div>
            <br />
            <Container>
              {OwnerFilter}
              <br />
              <Row className="justify-content-lg-between">
                <AchievmentsCategories
                  handleAchievementCategoryClick={this.handleAchievementCategoryClick}
                  selectedCategoryIds={this.state.selectedCategories}
                  selectedAll={this.state.selectedAll} />
              </Row>
              <br />
              <NavItems />
            </Container>
            <TabContent activeTab={activeTab}>
              <TabPane className="justify-content-center" tabId="1">
                {AchievementsGrid}
              </TabPane>
              <TabPane tabId="2">
                <MapComponent onRef={ref => (this.mapRef = ref)}
                  selectedCategoryIds={this.state.selectedCategories}
                  selectedOwnerType={this.state.ownerType.value} />
              </TabPane>
            </TabContent>
          </div>
        </div>
      </Layout >
    )
  }
}

const mapStateToProps = state => {
  return {
    regions: state.regions.regions,
    selectedRegionId: state.regions.selectedRegion && state.regions.selectedRegion.id,
    selectedRegionCode: state.regions.selectedRegion && state.regions.selectedRegion.code,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setActiveNavbarItem: navbarItem => dispatch(actions.setActiveNavbarItem(navbarItem)),
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(injectIntl(AchievmentsPage)))
